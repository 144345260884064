import { httpsCallable } from 'firebase/functions'
import { functions } from '../../firebase_config/firebase_config'
import { BrandData, InviteToBrandNotification, Permissions, Team, UserData } from '../../types'
import { AuditionFormSettings } from '../../components/Admin/Talent/AuditionForm/types'

type ResultState = {
    state: string
}

export namespace CloudFunctions {
    export namespace Invite {
        export type GetBrandDataArgs = {
            userId: string
            teamId: string
            brandId: string
        }

        export type GetBrandDataReturn = {
            type: 'invalid' | 'pending' | 'accepted'
            user?: UserData
            team?: Team
            brand?: BrandData
            notification?: InviteToBrandNotification
        }

        export const getBrandData = httpsCallable<GetBrandDataArgs, GetBrandDataReturn>(
            functions,
            'invite-getBrandData'
        )

        export type GetTeamDataArgs = {
            userId: string
            teamId: string
        }

        export type GetTeamDataReturn = {
            type: 'invalid' | 'pending' | 'accepted'
            user?: UserData
            team?: Team
        }

        export const getTeamData = httpsCallable<GetTeamDataArgs, GetTeamDataReturn>(functions, 'invite-getTeamData')

        export type CollabTeamArgs = { brandId: string; teamId: string }
        export type CollabTeamRes = { type: string; message?: string }

        export const cancelCollaboratingTeam = httpsCallable<CollabTeamArgs, CollabTeamRes>(
            functions,
            'invite-cancelTeamToBrand'
        )

        export const resendCollaboratingTeam = httpsCallable<CollabTeamArgs, CollabTeamRes>(
            functions,
            'invite-resendTeamToBrand'
        )
    }

    export namespace SignUp {
        type SendVerificationEmailArgs = { uid: string; price: string }

        export const sendVerificationEmail = httpsCallable<SendVerificationEmailArgs, ResultState>(
            functions,
            'signup-sendVerificationEmail'
        )

        type CreateUnverifiedUserArgs = {
            displayName: string
            email: string
            password: string
            priceNickname: string
            customOffer: string
            role?: 'owner' | 'talent' | ''
        }

        type CreateUnverifiedUserReturn = {
            uid: string
            error?: string
            success?: string
        }

        export const createUnverifiedUser = httpsCallable<CreateUnverifiedUserArgs, CreateUnverifiedUserReturn>(
            functions,
            'signup-createUnverifiedUser'
        )

        type FinishSignUpArgs = { uid: string; password: string; displayName: string }
        export const finishExisting = httpsCallable<FinishSignUpArgs, { emailVerified: boolean }>(
            functions,
            'signup-finishExisting'
        )

        export const createUnverifiedUserCampaign = httpsCallable<
            { displayName: string; email: string; phoneNumber: string; campaign: string; offerId: string },
            CreateUnverifiedUserReturn
        >(functions, 'signup-createUnverifiedUserCampaign')

        export const finishCampaign = httpsCallable<
            { uid: string; password: string },
            {
                success: string
                token: string
            }
        >(functions, 'signup-finishCampaign')

        export const markOnboardingComplete = httpsCallable<{ uid: string }, ResultState>(
            functions,
            'signup-markOnboardingComplete'
        )

        export const resendVerificationEmailCampaign = httpsCallable<{ uid: string }, void>(
            functions,
            'signup-resendVerificationEmailCampaign'
        )

        export const setFirstSignIn = httpsCallable<{ uid: string }, void>(functions, 'signup-setFirstSignIn')

        export const verifyEmailCode = httpsCallable<
            {
                uid: string
                code: string
            },
            {
                success: string
            }
        >(functions, 'signup-verifyEmailCode')

        export const finishWithNoTeam = httpsCallable<
            {
                uid: string
                defaultAuditionSettings: AuditionFormSettings
                defaultPermissions: Permissions
            },
            {
                token: string
            }
        >(functions, 'signup-finishWithNoTeam')
    }

    export const finishSignUpTalent = httpsCallable<
        { email: string; displayName: string; password: string },
        {
            success: string
            token: string
        }
    >(functions, 'talent-finishSignUp')

    export namespace Support {
        export const createTicket = httpsCallable<
            { email: string; name: string; category: string; description: string },
            { success: string }
        >(functions, 'support-createTicket')
    }
}
